import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { CheckboxLabel } from 'src/components/CheckboxInput'
import { RadioLabel } from 'src/components/RadioInput'

export const BASE_FONT_SIZE = 18

export const fontFamilies =
  'MaisonNeue, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'

export const baseHeadingStyles = css`
  font-family: ${fontFamilies};
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.325em;
`

export const TextH1 = styled.h1`
  ${baseHeadingStyles}
  font-size: 32px;
`

export const TextH2 = styled.h2`
  ${baseHeadingStyles}
  font-size: 24px;
`

export const TextH3 = styled.h3`
  ${baseHeadingStyles}
  font-size: 22px;
`

export const baseTextStyles = css`
  font-family: ${fontFamilies};
  font-size: ${BASE_FONT_SIZE}px;
`

export const TextParagraph = styled.p`
  ${baseTextStyles}
  font-weight: 300;
  line-height: 1.5;
  word-break: break-word;
`

export const TextSpan = styled.span`
  ${baseTextStyles}
  font-weight: 300;
  font-size: inherit;

  ${TextH1} &,
  ${TextH2} &,
  ${TextH3} &,
  ${TextParagraph} &,
  ${CheckboxLabel} &,
  ${RadioLabel} & {
    font-weight: inherit;
  }
`

export const codeTextStyles = css`
  font-family: MaisonNeueMono, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
`

export const TextCode = styled.code`
  ${baseTextStyles}
  ${codeTextStyles}
  font-size: inherit;
  font-weight: 300;
  letter-spacing: -0.07em;
`
