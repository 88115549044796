import styled from '@emotion/styled'

import COLOR from 'src/constants/color'
import { MIN_MEDIA } from 'src/constants/media-query'

const Card = styled.div`
  border: 1px solid ${COLOR.GREY_04};
  background: ${COLOR.WHITE};
  border-radius: 12px;

  padding: 28px 24px;
  ${MIN_MEDIA.$800} {
    padding: 40px 32px;
  }
`

export default Card
