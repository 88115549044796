import { useEffect, useState } from 'react';
import useTranslate from 'src/hooks/useTranslate';
const titlesMap = new Map();
let current = 0;
function getActiveTitle() { return Array.from(titlesMap.values()).reverse().find(value => value != null); }
export default function useDocumentTitle(documentTitle) {
    const { translateToString } = useTranslate();
    const defaultTitle = translateToString('document:title'); // Effect to maintain the active title
    const [id] = useState(() => current += 1);
    useEffect(() => { titlesMap.set(id, documentTitle); return () => { titlesMap.set(id, undefined); }; }, [id, documentTitle]); // Effect to update the document title, only when the component is the last
    // component to use this hook.
    useEffect(() => {
        const format = customTitle => customTitle === '' ? defaultTitle : "".concat(customTitle, " \u2022 ").concat(defaultTitle);
        if (getActiveTitle() === documentTitle) {
            document.title = format(documentTitle);
        }
        return () => {
            // Otherwise, reset the title to the previously active title.
            const activeTitle = getActiveTitle();
            if (activeTitle == null) {
                document.title = defaultTitle;
                titlesMap.clear();
            }
            else {
                document.title = format(activeTitle);
            }
        };
    }, [documentTitle, defaultTitle, translateToString]);
}
