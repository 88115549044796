import { Buffer } from 'buffer'
import 'normalize.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import App from 'src/App'
import reportWebVitals from 'src/reportWebVitals'
import RootProviders from 'src/root/RootProviders'
import RootStyles from 'src/root/RootStyles'

// Webpack 5 no longer automatically defines Node.js globals, so do it manually
window.Buffer = Buffer

ReactDOM.render(
  <StrictMode>
    <RootProviders>
      <RootStyles />
      <App />
    </RootProviders>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
