import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { getNewRoleEquivalent } from 'src/utils/user';
const userState = atom({ key: 'user', default: null });
function maskLegacyRole(user) { return { ...user, role: getNewRoleEquivalent(user.role) }; }
export default function useUser() { let { useNewRolesOnly } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { useNewRolesOnly: true }; const user = useRecoilValue(userState); if (user == null) {
    throw new Error('No user found in context');
} if (useNewRolesOnly) {
    return maskLegacyRole(user);
} return user; }
export function useOptionalUser() { return useRecoilValue(userState); }
export function useSetUser() { return useSetRecoilState(userState); }
const userLoadingState = atom({ key: 'userLoading', default: 'loading' });
export function useUserLoading() { return useRecoilValue(userLoadingState); }
export function useSetUserLoading() { return useSetRecoilState(userLoadingState); }
