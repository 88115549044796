import styled from '@emotion/styled'

import { baseTextStyles } from 'src/components/Text'

const PlainButton = styled('button', {
  shouldForwardProp: () => true,
})`
  -webkit-tap-highlight-color: transparent;

  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  color: inherit;

  ${baseTextStyles}

  display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover,
  :focus {
    outline: none;
    cursor: pointer;
  }
  :disabled {
    cursor: not-allowed;
  }
`

PlainButton.defaultProps = {
  type: 'button',
}

export { PlainButton as default }

export type PlainButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
