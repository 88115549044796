import { useLocalStorage } from '@rehooks/local-storage';
import { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
export function useStoredCode(storageKey) { var _storedDiscount$code; const [storedDiscount] = useLocalStorage(storageKey); return (_storedDiscount$code = storedDiscount === null || storedDiscount === void 0 ? void 0 : storedDiscount.code) !== null && _storedDiscount$code !== void 0 ? _storedDiscount$code : null; }
export function useInitializeDiscount(_ref) {
    let { queryKey, storageKey } = _ref;
    const [newDiscountCode] = useQueryParam(queryKey, StringParam);
    const [visitedDiscount, setVisitedDiscount, removeVisitedDiscount] = useLocalStorage(storageKey); // When the user visits a new discount code, store it
    useEffect(() => { if (newDiscountCode == null || newDiscountCode === '') {
        return;
    } const newVisitedDiscount = { timestamp: Date.now(), code: newDiscountCode }; setVisitedDiscount(newVisitedDiscount); }, [newDiscountCode, setVisitedDiscount]); // When the visited discount expires, remove it from local storage
    useEffect(() => { if (visitedDiscount == null) {
        return;
    } if (visitedDiscount.timestamp + 30 * 24 * 60 * 60 * 1000 < Date.now()) {
        removeVisitedDiscount();
    } }, [visitedDiscount, removeVisitedDiscount]);
}
