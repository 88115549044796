import { css } from '@emotion/react'

import { BREAKPOINT, MIN_MEDIA } from 'src/constants/media-query'

interface SpacerProps {
  unit: number | Record<BREAKPOINT, number>
}

export default function Spacer({ unit }: SpacerProps): React.ReactElement {
  if (typeof unit === 'number') {
    return (
      <div
        css={css`
          flex-shrink: 0;
          width: ${unit * 4}px;
          height: ${unit * 4}px;
        `}
      />
    )
  }

  return (
    <div
      css={css`
        flex-shrink: 0;
        width: ${unit[BREAKPOINT.$480] * 4}px;
        height: ${unit[BREAKPOINT.$480] * 4}px;

        ${MIN_MEDIA.$800} {
          width: ${unit[BREAKPOINT.$800] * 4}px;
          height: ${unit[BREAKPOINT.$800] * 4}px;
        }

        ${MIN_MEDIA.$1080} {
          width: ${unit[BREAKPOINT.$1080] * 4}px;
          height: ${unit[BREAKPOINT.$1080] * 4}px;
        }
      `}
    />
  )
}

const flexGrowStyle = css`
  flex-grow: 1;
`

interface SpacerFlexProps {
  minUnit: number
}

export function SpacerFlex({ minUnit }: SpacerFlexProps): React.ReactElement {
  return (
    <div css={flexGrowStyle}>
      <Spacer unit={minUnit} />
    </div>
  )
}

interface SpacerByBreakpointProps {
  breakpoint: Record<BREAKPOINT, { unit: number; flex?: boolean }>
}

export function SpacerByBreakpoint({
  breakpoint,
}: SpacerByBreakpointProps): React.ReactElement {
  return (
    <>
      <div
        css={[
          breakpoint[BREAKPOINT.$480].flex === true && flexGrowStyle,
          css`
            display: block;
            ${MIN_MEDIA.$800} {
              display: none;
            }
            ${MIN_MEDIA.$1080} {
              display: none;
            }
          `,
        ]}
      >
        <Spacer unit={breakpoint[BREAKPOINT.$480].unit} />
      </div>

      <div
        css={[
          breakpoint[BREAKPOINT.$800].flex === true && flexGrowStyle,
          css`
            display: none;
            ${MIN_MEDIA.$800} {
              display: block;
            }
            ${MIN_MEDIA.$1080} {
              display: none;
            }
          `,
        ]}
      >
        <Spacer unit={breakpoint[BREAKPOINT.$800].unit} />
      </div>

      <div
        css={[
          breakpoint[BREAKPOINT.$1080].flex === true && flexGrowStyle,
          css`
            display: none;
            ${MIN_MEDIA.$1080} {
              display: block;
            }
          `,
        ]}
      >
        <Spacer unit={breakpoint[BREAKPOINT.$1080].unit} />
      </div>
    </>
  )
}
