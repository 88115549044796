export default function tag(strings) { for (var _len = arguments.length, keys = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    keys[_key - 1] = arguments[_key];
} return function get(data) { const firstChunk = strings[0]; if (typeof firstChunk !== 'string') {
    console.error('Invalid string tagged in %o', strings);
    return '';
} const result = [firstChunk]; keys.forEach((key, index) => { const chunk = strings[index + 1]; if (typeof chunk !== 'string') {
    console.error('Invalid key %o tagged at index %o', key, index);
    return;
} const value = getValue(data, key); result.push(value, chunk); }); return result.join(''); }; }
function getValue(data, key) {
    const value = typeof key === 'function' ? key(data) : data[key];
    if (typeof value !== 'number' && typeof value !== 'string') {
        console.error('Invalid value type %o for key %o', typeof value, key); // eslint-disable-next-line no-debugger
        debugger;
        return '';
    }
    return value;
}
