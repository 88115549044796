import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { ERROR_CODE, RESPONSE_STATUS } from 'src/constants/api';
import { LOGIN_FAILURE_REASON } from 'src/features/auth/constants/errors';
import useTranslate from 'src/hooks/useTranslate'; // Constants for error names and codes
const PASSKEY_DISMISSED_ERROR_NAME = 'NotAllowedError';
const PASSKEY_ERROR_MESSAGE_NOT_FOCUSED = new RegExp(/not focused/i);
const PASSKEY_ERROR_INVALID_RP_ID = new RegExp(/invalid for this domain/i); /**
 * The webauthn error message is identical when a user dismisses a passkey prompt (expected) versus when a browser
 * auto-cancels a passkey prompt (unexpected, common in WebKit browsers). This constant is used as a predictor
 * to discern between the two cases. If the time to failure is greater than the expected dismissal time, it is
 * more likely that the user manually dismissed the prompt and should be treated as such.
 */
export const PASSKEY_PROMPT_EXPECT_USER_DISMISSAL_MS = 200; // Keys for the different types of help messages, used to determine the content of the help modal
export const AUTH_HELP_TYPE = { NEED_HELP: 'need_help', BROWSER_NOT_SUPPORTED: 'browser_not_supported', USE_OTHER_PASSKEY: 'use_other_passkey', USE_OTHER_PASSWORD: 'use_other_method' };
export default function useAuthError() {
    const { translate } = useTranslate(); /**
       * Setting a help type in parsing auth errors can trigger the display
       * of complex UI elements. This type of error display are spec'd in
       * newer auth flows such as TOTP and passkey login, but may not be
       * supported in legacy flows.
       *
       * This fallback allows errors from legacy flows (password sign-in
       * and sign-up) to be handled without adding conditional logic to
       * display unnecessary UI elements. If this error is triggered, the
       * screen should be updated to include a HelpModal or similar UI.
       */
    function setHelpTypeFallback(type) { console.error('setHelpType not provided, UI not updated for', type); }
    const parseRemoteError = useCallback(_ref => {
        var _res$error$code2;
        let { res, credentials, setHelpType = setHelpTypeFallback } = _ref;
        if (res.error == null || res.status == null) {
            throw new Error('Unexpected response (without data and error)');
        }
        const errorStatus = res.status; // Handle error codes based on the response status
        if (errorStatus === RESPONSE_STATUS.UNPROCESSABLE) {
            setHelpType(AUTH_HELP_TYPE.USE_OTHER_PASSWORD);
            return { error: 'User does not have any active passkeys', reason: LOGIN_FAILURE_REASON.PASSKEYS_UNAVAILABLE };
        }
        if (errorStatus === RESPONSE_STATUS.GONE) {
            setHelpType(AUTH_HELP_TYPE.USE_OTHER_PASSKEY);
            return { error: 'Selected passkey has been deleted', reason: LOGIN_FAILURE_REASON.PASSKEY_DELETED };
        }
        if (errorStatus === RESPONSE_STATUS.NOT_ACCEPTABLE) {
            setHelpType(AUTH_HELP_TYPE.USE_OTHER_PASSKEY);
            return { error: 'Unable to recognize user from auth response', reason: LOGIN_FAILURE_REASON.USER_NOT_FOUND };
        }
        if (errorStatus === RESPONSE_STATUS.LOCKED) {
            toast.error(translate('locked_account'), { id: 'locked-account-error' });
            return { error: 'locked' };
        } // For non-specific error statuses, parse the error code
        const errorCode = res.error.code;
        if (typeof res.error !== 'string' && errorCode === ERROR_CODE.EMAIL_UNVERIFIED) {
            return { reason: LOGIN_FAILURE_REASON.EMAIL_VERIFICATION_REQUIRED, error: errorCode, credentials };
        }
        if (errorCode === ERROR_CODE.TOTP_REQUIRED) {
            return { error: 'TOTP is required', reason: LOGIN_FAILURE_REASON.TOTP_REQUIRED, credentials };
        }
        if ((errorCode === null || errorCode === void 0 ? void 0 : errorCode.includes(ERROR_CODE.TOTP_INVALID)) === true) {
            return { error: 'TOTP is required', reason: LOGIN_FAILURE_REASON.TOTP_INVALID, credentials };
        }
        if (errorCode === ERROR_CODE.TWO_FACTOR_REQUIRED) {
            return { error: 'Two-factor authentication is required', reason: LOGIN_FAILURE_REASON.TWO_FACTOR_REQUIRED, credentials };
        }
        if (errorCode === ERROR_CODE.PASSKEY_REQUIRED) {
            return { error: 'Passkey is required', reason: LOGIN_FAILURE_REASON.PASSKEY_REQUIRED, credentials };
        }
        if (errorStatus === RESPONSE_STATUS.UNAUTHORIZED) {
            var _res$error$code;
            toast.error(translate('invalid_email_password'), { id: 'invalid-email-password-error' });
            return { error: (_res$error$code = res.error.code) !== null && _res$error$code !== void 0 ? _res$error$code : res.error.message };
        }
        toast.error(translate('login_error_refresh'), { id: 'invalid-email-unknown' }); /**
               * Preserved from legacy error handling.
               *
               * This is a catch-all and should not be used for further error handling.
               * Instead, handle known cases above and add typed error reasons as needed.
               */
        return { error: (_res$error$code2 = res.error.code) !== null && _res$error$code2 !== void 0 ? _res$error$code2 : res.error.message };
    }, [translate]);
    const parsePasskeyPromptError = useCallback(_ref2 => {
        let { error, setHelpType, timeToFailureMs } = _ref2;
        const isNotFocusedError = PASSKEY_ERROR_MESSAGE_NOT_FOCUSED.test(error.message);
        if (isNotFocusedError) {
            setHelpType(AUTH_HELP_TYPE.NEED_HELP);
            return { error: error.message, reason: LOGIN_FAILURE_REASON.PASSKEY_PROMPT_DENIED };
        }
        const invalidRpError = PASSKEY_ERROR_INVALID_RP_ID.test(error.message);
        if (invalidRpError) {
            setHelpType(AUTH_HELP_TYPE.BROWSER_NOT_SUPPORTED);
            return { error: error.message, reason: LOGIN_FAILURE_REASON.PASSKEY_PROMPT_DENIED };
        } /**
        * The error is identical for user dismissal and browser cancellation. The time to
        * failure is used as a predictor to discern between the two cases.
        */
        const didUserDismissPrompt = timeToFailureMs >= PASSKEY_PROMPT_EXPECT_USER_DISMISSAL_MS && error.name === PASSKEY_DISMISSED_ERROR_NAME;
        if (didUserDismissPrompt) {
            setHelpType(AUTH_HELP_TYPE.NEED_HELP);
            return { error: error.message, reason: LOGIN_FAILURE_REASON.PASSKEY_PROMPT_DISMISSED };
        }
        setHelpType(AUTH_HELP_TYPE.BROWSER_NOT_SUPPORTED);
        return { error: error.message, reason: LOGIN_FAILURE_REASON.PASSKEY_PROMPT_DENIED };
    }, []);
    return { parseRemoteError, parsePasskeyPromptError };
}
