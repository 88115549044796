import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { baseTextStyles } from 'src/components/Text'
import {
  baseInputStyles,
  inputStylesSmall,
  inputStylesMedium,
  inputStylesLarge,
  hoverInputStyles,
  focusInputStyles,
  errorInputStyles,
  errorHoverInputStyles,
  errorFocusInputStyles,
} from 'src/components/TextInput'
import COLOR from 'src/constants/color'
import { BORDER_WIDTH, ELEMENT_HEIGHT } from 'src/constants/form-elements'

export const RadioLabel = styled.label``

export interface RadioInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'label' | 'size'> {
  labelCss?: SerializedStyles | SerializedStyles[]
  labelContent: React.ReactElement
  name: string
  size?: Lowercase<keyof typeof ELEMENT_HEIGHT>
  state?: 'error'
}

export default forwardRef(function RadioInput(
  {
    labelCss,
    labelContent,
    checked,
    name,
    size,
    state,
    ...props
  }: RadioInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement {
  // prettier-ignore
  const iconSize =
    size === 'small' ? 16 :
    size === 'default' ? 20 :
    24

  // prettier-ignore
  const innerIconSize =
    size === 'small' ? 8 :
    size === 'default' ? 10 :
    12

  return (
    <RadioLabel
      css={[
        baseInputStyles,
        baseTextStyles,
        size === 'small' && inputStylesSmall,
        size === 'medium' && inputStylesMedium,
        size === 'large' && inputStylesLarge,
        css`
          font-weight: 700;

          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: ${size === 'small' ? 8 : 12}px;
          align-items: center;

          ${state === 'error' ? errorInputStyles : undefined}

          :hover {
            cursor: pointer;
            ${state === 'error' ? errorHoverInputStyles : hoverInputStyles}
          }

          :focus-within {
            ${state === 'error' ? errorFocusInputStyles : focusInputStyles}
          }
        `,
        labelCss,
      ]}
    >
      <input
        {...props}
        css={css`
          pointer-events: none;
          opacity: 0;
          position: absolute;
        `}
        ref={ref}
        type='radio'
        checked={checked}
        name={name}
      />
      <div
        css={[
          css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: ${iconSize}px;
            height: ${iconSize}px;

            border-radius: ${iconSize / 2}px;
            border: ${BORDER_WIDTH}px solid ${COLOR.PURPLE_400};
            background: ${COLOR.WHITE};
          `,
          state === 'error' &&
            css`
              border-color: ${COLOR.PINK_500};
            `,
        ]}
      >
        <div
          css={[
            css`
              width: ${innerIconSize}px;
              height: ${innerIconSize}px;
              border-radius: ${innerIconSize / 2}px;

              background: ${COLOR.WHITE};
              input:hover:not(:disabled):not(:focus):not(:checked) + div > & {
                background: ${COLOR.PURPLE_400}33;
              }
              input:checked + div > & {
                background: ${COLOR.PURPLE_400};
              }
            `,
            state === 'error' &&
              css`
                input:hover:not(:disabled):not(:focus):not(:checked) + div > & {
                  background: ${COLOR.PINK_500}33;
                }
              `,
          ]}
        />
      </div>
      <div>{labelContent}</div>
    </RadioLabel>
  )
})
