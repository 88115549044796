import { css, SerializedStyles } from '@emotion/react'
import { AnimatePresence, AnimatePresenceProps, motion } from 'framer-motion'

import { DURATION, OFFSET } from 'src/constants/animation'

export interface AnimatedContentProps {
  children: React.ReactElement | React.ReactNode[]
  visible: boolean
  delay?: number
  elementCss?: SerializedStyles | SerializedStyles[]
  onExitComplete?: AnimatePresenceProps['onExitComplete']
}

export function AnimatedFlowRoot({
  children,
  visible,
  elementCss,
  onExitComplete,
}: Omit<AnimatedContentProps, 'delay'>): React.ReactElement {
  return (
    <AnimatePresence onExitComplete={onExitComplete}>
      {visible && (
        <motion.div
          data-testid='animated-flow-root'
          css={elementCss}
          initial='hiddenRoot'
          animate='visibleRoot'
          exit='hiddenRoot'
          variants={{
            hiddenRoot: { opacity: 0 },
            visibleRoot: { opacity: 1 },
          }}
          transition={{
            duration: DURATION.OPEN_CLOSE,
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export function AnimatedHeaderItem({
  children,
  visible,
  delay = 0,
  elementCss,
  onExitComplete,
}: AnimatedContentProps): React.ReactElement {
  return (
    <AnimatePresence onExitComplete={onExitComplete}>
      {visible && (
        <motion.div
          data-testid='animated-header-item'
          css={elementCss}
          initial='hidden'
          animate='show'
          exit='hide'
          variants={{
            hidden: {
              opacity: 0,
              y: OFFSET.SLIDE_IN,
            },
            show: {
              opacity: 1,
              y: 0,
              transition: {
                delay: delay ?? 0,
                duration: DURATION.FADE_IN,
              },
            },
            hide: {
              opacity: 0,
              transition: {
                duration: DURATION.FADE_OUT,
              },
            },
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export interface AnimatedMainContentProps extends AnimatedContentProps {
  centered?: boolean
}

export function AnimatedMainContent({
  children,
  visible,
  centered,
  elementCss,
  onExitComplete,
  delay = DURATION.STAGGER,
}: AnimatedMainContentProps): React.ReactElement {
  return (
    <AnimatePresence onExitComplete={onExitComplete}>
      {visible === true && (
        <motion.main
          data-testid='animated-main-content'
          css={[
            ...(Array.isArray(elementCss) ? elementCss : [elementCss]),
            css`
              width: 100%;
              flex-grow: 1;
              overflow-y: auto;
            `,
          ]}
          initial='hidden'
          animate='show'
          exit='hide'
          variants={{
            hidden: {
              opacity: 0,
              y: OFFSET.SLIDE_IN,
            },
            show: {
              opacity: 1,
              y: 0,
              transition: {
                delay: delay ?? DURATION.STAGGER,
                duration: DURATION.FADE_IN,
              },
            },
            hide: {
              opacity: 0,
              transition: {
                duration: DURATION.FADE_OUT,
              },
            },
          }}
        >
          <div
            css={[
              css`
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: center;
              `,
              centered === true &&
                css`
                  justify-content: center;
                `,
            ]}
          >
            {children}
          </div>
        </motion.main>
      )}
    </AnimatePresence>
  )
}

export interface AnimatedCtaContentProps {
  children: React.ReactElement | React.ReactNode[]
  delay?: number
  elementCss?: SerializedStyles | SerializedStyles[]
}

export function AnimatedCtaContent({
  children,
  delay = 0,
  elementCss,
}: AnimatedCtaContentProps): React.ReactElement {
  return (
    <motion.div
      data-testid='animated-cta-content'
      id='animated-cta-content'
      css={elementCss}
      variants={{
        hidden: {
          opacity: 0,
          y: OFFSET.SLIDE_IN,
        },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            delay: delay + DURATION.STAGGER * 2,
            duration: DURATION.FADE_IN,
          },
        },
      }}
    >
      {children}
    </motion.div>
  )
}
