import { css } from '@emotion/react'
import { forwardRef } from 'react'

import { baseTextStyles } from 'src/components/Text'
import COLOR from 'src/constants/color'
import {
  ELEMENT_HEIGHT,
  BORDER_RADIUS,
  BORDER_WIDTH,
  OUTLINE_THICKNESS,
  HORIZONTAL_PADDING,
} from 'src/constants/form-elements'
import { opacity } from 'src/utils/color'

export interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  onChangeText?: (value: string) => void
  size?: Lowercase<keyof typeof ELEMENT_HEIGHT>
  state?: 'error'
  innerRef?: React.Ref<HTMLInputElement>
}

export const baseInputStyles = {
  padding: `0 ${HORIZONTAL_PADDING.DEFAULT}px`,
  width: '100%',
  height: `${ELEMENT_HEIGHT.DEFAULT}px`,

  color: COLOR.BLACK,
  background: COLOR.WHITE,
  border: `${BORDER_WIDTH}px solid ${COLOR.GREY_04}`,
  borderRadius: `${BORDER_RADIUS}px`,
}

export const basePlaceholderStyles = {
  color: COLOR.GREY_02,
}

export const hoverInputStyles = {
  border: `${BORDER_WIDTH}px solid ${opacity(COLOR.PURPLE_400, 0.6)}`,
}

export const focusInputStyles = {
  border: `${BORDER_WIDTH}px solid ${COLOR.PURPLE_400}`,
  boxShadow: `0 0 0 ${OUTLINE_THICKNESS}px ${opacity(COLOR.PURPLE_400, 0.2)}`,
}

export const errorInputStyles = {
  border: `${BORDER_WIDTH}px solid ${COLOR.PINK_500}`,
}

export const errorHoverInputStyles = {
  boxShadow: `0 0 0 ${OUTLINE_THICKNESS}px ${opacity(COLOR.PINK_500, 0.13)}`,
}

export const errorFocusInputStyles = {
  boxShadow: `0 0 0 ${OUTLINE_THICKNESS}px ${opacity(COLOR.PINK_500, 0.25)}`,
}

const inputStyles = (state: TextInputProps['state']) => css`
  /* Reset the default styles */
  font: inherit;
  appearance: none; /* Fix input rendering differently on Safari */
  min-width: 0; /* Safari has an implicit input min-width it seems */
  :focus {
    outline: none;
  }

  ${baseInputStyles}
  ${state === 'error' ? errorInputStyles : undefined}

  ::placeholder {
    ${basePlaceholderStyles}
  }

  :hover:not(:disabled):not(:read-only):not(:focus) {
    ${state === 'error' ? errorHoverInputStyles : hoverInputStyles}
  }
  :focus:not(:disabled):not(:read-only) {
    ${state === 'error' ? errorFocusInputStyles : focusInputStyles}
  }

  :disabled,
  :read-only {
    color: ${COLOR.GREY_02};
    background: ${opacity(COLOR.GREY_04, 0.6)};
    border-color: ${COLOR.GRAY_500};
    cursor: not-allowed;
  }
`

export const inputStylesCode = {
  fontSize: '40px',
  fontWeight: 700,
  textAlign: 'center',
  padding: `0 ${HORIZONTAL_PADDING.CODE}px`,
  height: `${ELEMENT_HEIGHT.CODE}px`,
  width: `${ELEMENT_HEIGHT.CODE}px`,
}

export const inputStylesSmall = {
  fontSize: '13px',
  padding: `0 ${HORIZONTAL_PADDING.SMALL}px`,
  height: `${ELEMENT_HEIGHT.SMALL}px`,
}

export const inputStylesMedium = {
  padding: `0 ${HORIZONTAL_PADDING.MEDIUM}px`,
  height: `${ELEMENT_HEIGHT.MEDIUM}px`,
}

export const inputStylesLarge = {
  padding: `0 ${HORIZONTAL_PADDING.LARGE}px`,
  height: `${ELEMENT_HEIGHT.LARGE}px`,
}

export default forwardRef(function TextInput(
  {
    value,
    onChange,
    onChangeText,
    size,
    type,
    state,
    innerRef,
    ...props
  }: TextInputProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>,
) {
  const onChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange != null) {
      onChange(event)
    }
    if (onChangeText != null) {
      onChangeText(event.currentTarget.value)
    }
  }

  /**
   * If an innerRef is provided, use it. Otherwise, use the default forwardedRef.
   * Forwarded refs are used to pass refs from parent components to child components,
   * but might override some default behavior of the input element.
   */
  const ref = innerRef ?? forwardedRef

  return (
    <input
      ref={ref}
      value={value}
      onChange={onChangeLocal}
      type={type ?? 'text'}
      css={[
        inputStyles(state),
        baseTextStyles,
        size === 'code' && inputStylesCode,
        size === 'small' && inputStylesSmall,
        size === 'medium' && inputStylesMedium,
        size === 'large' && inputStylesLarge,
        state === 'error' && errorInputStyles,
        css`
          ::-webkit-search-cancel-button {
            cursor: pointer;
            opacity: 1;
            margin-left: ${HORIZONTAL_PADDING.DEFAULT - 1}px;
          }
        `,
      ]}
      {...props}
    />
  )
})

const nativeInputValueSetter =
  typeof window === 'undefined'
    ? () => {
        /* empty */
      }
    : // eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/no-non-null-assertion
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )!.set

/**
 * A method to help clear the value of an input element using UI
 * that mimics the "clear" button behavior of search inputs.
 */
export function clearInputValue(ref: React.RefObject<HTMLInputElement>): void {
  if (ref.current == null) {
    throw new Error('Cannot clear the value of an input that is not mounted')
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  nativeInputValueSetter!.call(ref.current, '')
  ref.current.dispatchEvent(new Event('input', { bubbles: true }))
}
