import { useLocalStorage } from '@rehooks/local-storage';
import { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import useApi from 'src/hooks/useApi'; // Used to index the Recoil state and local storage for the geolocation metadata
const GEOLOCATION_KEY = 'geo'; // Expire the geolocation metadata cache after 3 hours
const GEOLOCATION_CACHE_EXPIRATION_MS = 1000 * 60 * 60 * 3; /**
 * Basic information about a browser's geolocation.
 *
 * Metadata should be very high level and should not contain any sensitive
 * information beyond what is necessary for basic application compliance.
 */ /**
* The local storage version of the geolocation metadata includes an expiration
* Note, these property names will be exposed to the client so they are prone
* to being manipulated by users.
*/ /**
* The return type for the useGeolocation hook.
*
* Includes a loading state to control UI if render logic depends on
* the geolocation metadata.
*/ /**
* The geolocation metadata should be stored in the Recoil state to allow
* for easy access to the metadata without excessive loading states on the
* component level.
*/
const GEO_STATE = atom({ key: GEOLOCATION_KEY, default: null }); /**
* A wrapper around local storage to cache the geolocation metadata, includes a getter
*  and setter with expire-on-use functionality.
*
* @warning This cache is not secure and should not be used for sensitive data.
* All information in local storage is exposed to the client and prone to manipulation.
*/
function useGeoMetaCache() {
    const [localGeoMeta, setLocalGeoMeta, removeLocalGeoMeta] = useLocalStorage(GEOLOCATION_KEY); // Returns a valid, unexpired geolocation metadata object or undefined
    const getCachedMeta = useCallback(() => {
        if (!localGeoMeta) {
            return undefined;
        } // If an expired value is present, remove it and return undefined
        if (localGeoMeta.exp < Date.now()) {
            removeLocalGeoMeta();
            return undefined;
        } // Return the valid metadata
        return localGeoMeta;
    }, [localGeoMeta, removeLocalGeoMeta]); // Add a new geolocation metadata object to the cache
    const setCachedMeta = useCallback(meta => { setLocalGeoMeta({ ...meta, exp: Date.now() + GEOLOCATION_CACHE_EXPIRATION_MS }); }, [setLocalGeoMeta]);
    return { getCachedMeta, setCachedMeta };
} /**
 * Loads a browser's geolocation metadata, in order of availability:
 *  1. Recoil state
 *  2. Local storage cache
 *  3. API fetch
 *
 * Returns an info-light object about a browser's geolocation to manage
 * basic UI and application logic.
 *
 * @warning The results of this hook are not secure, and should be used for
 * UI hints only. A user can manipulate their geolocation metadata by
 * modifying the local storage cache, or using a VPN that changes their IP.
 */
export default function useGeolocation() {
    const [loading, setLoading] = useState(true);
    const { getCachedMeta, setCachedMeta } = useGeoMetaCache();
    const meta = useRecoilValue(GEO_STATE);
    const setMeta = useSetRecoilState(GEO_STATE);
    const { fetch: fetchGeoMeta } = useApi({ serviceName: 'vault', path: 'application/geo', method: 'GET' });
    useEffect(() => {
        if (meta) {
            setLoading(false);
            return;
        } // 2. If metadata is available in the cache, set it and resolve loading
        const cachedMeta = getCachedMeta();
        if (cachedMeta) {
            setMeta(cachedMeta);
            setLoading(false);
            return;
        } // 3. Otherwise, fetch the geolocation metadata from the API when the component mounts
        void run().then(() => { setLoading(false); });
        async function run() {
            try {
                const res = await fetchGeoMeta();
                if (res.data == null) {
                    throw new Error('No geolocation metadata found');
                }
                setMeta(res.data);
                setCachedMeta(res.data);
            }
            catch (error) {
                console.error('Failed to set geolocation metadata', error);
            }
            finally { // Regardless of error states, resolve loading
                setLoading(false);
            }
        }
    }, [fetchGeoMeta, meta, setMeta, getCachedMeta, setCachedMeta]);
    return { ...meta, loading };
}
