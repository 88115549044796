import { css } from '@emotion/react'

import {
  ButtonProps,
  baseButtonTextStyles,
  baseButtonStyles,
  alternateButtonStyles,
  alternateInvertedButtonStyles,
  outlinedButtonStyles,
  secondaryButtonStyles,
  interactiveButtonStyles,
  interactiveButtonSpanStyles,
} from 'src/components/Button'
import {
  ExternalLink,
  ExternalLinkProps,
  InternalLink,
  InternalLinkProps,
} from 'src/components/Link'

const linkAsButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
  }

  :hover,
  :focus {
    text-decoration: none;
  }
`

export interface ExternalLinkAsButtonProps extends ExternalLinkProps {
  children?: ButtonProps['children']
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
}

export function ExternalLinkAsButton({
  children,
  size,
  variant,
  ...props
}: ExternalLinkAsButtonProps): React.ReactElement {
  return (
    <ExternalLink
      css={[
        linkAsButtonStyles,
        baseButtonStyles({ size }),
        variant === 'alternate' ? alternateButtonStyles() : undefined,
        variant === 'alternate-inverted'
          ? alternateInvertedButtonStyles()
          : undefined,
        variant === 'outlined' ? outlinedButtonStyles() : undefined,
        variant === 'secondary' ? secondaryButtonStyles() : undefined,
        interactiveButtonStyles({ variant }),
      ]}
      {...props}
      innerCss={baseButtonTextStyles({ size })}
    >
      <span css={interactiveButtonSpanStyles}>{children}</span>
    </ExternalLink>
  )
}

export interface InternalLinkAsButtonProps<LinkState>
  extends InternalLinkProps<LinkState> {
  children?: ButtonProps['children']
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
}

export function InternalLinkAsButton<LinkState>({
  children,
  size,
  variant,
  ...props
}: InternalLinkAsButtonProps<LinkState>): React.ReactElement {
  return (
    <InternalLink
      css={[
        linkAsButtonStyles,
        baseButtonStyles({ size }),
        variant === 'alternate' ? alternateButtonStyles() : undefined,
        variant === 'alternate-inverted'
          ? alternateInvertedButtonStyles()
          : undefined,
        variant === 'outlined' ? outlinedButtonStyles() : undefined,
        variant === 'secondary' ? secondaryButtonStyles() : undefined,
        interactiveButtonStyles({ variant }),
      ]}
      {...props}
      innerCss={baseButtonTextStyles({ size })}
    >
      <span css={interactiveButtonSpanStyles}>{children}</span>
    </InternalLink>
  )
}
