import { css } from '@emotion/react'
import { Toaster, ToastBar } from 'react-hot-toast'

import Spacer from 'src/components/Spacer'
import { TextSpan } from 'src/components/Text'
import COLOR from 'src/constants/color'

const TOAST_OPTIONS = {
  style: {
    maxWidth: '400px',
    padding: '16px 20px',
    borderRadius: '12px',
    boxShadow: `0 4px 20px 4px ${COLOR.RICH_BLACK}1a, 0 0 6px ${COLOR.RICH_BLACK}0d`,
  },
  success: {
    duration: 5000,
    iconTheme: {
      primary: COLOR.TEAL_500,
      secondary: COLOR.WHITE,
    },
  },
  error: {
    duration: 5000,
    iconTheme: {
      primary: COLOR.PINK_500,
      secondary: COLOR.WHITE,
    },
  },
  loading: {
    iconTheme: {
      primary: COLOR.BLACK,
      secondary: COLOR.GREY_04,
    },
  },
}

export default function RootToaster(): React.ReactElement {
  return (
    <Toaster
      toastOptions={TOAST_OPTIONS}
      containerStyle={{
        top: 80,
      }}
    >
      {(toast) => (
        <ToastBar toast={toast}>
          {({ icon, message }) => (
            <>
              <span
                css={css`
                  > div > div > div:before,
                  > div > div > div:after {
                    height: 2.5px;
                  }
                `}
              >
                {icon}
              </span>
              <Spacer unit={3} />
              <TextSpan
                css={css`
                  font-weight: 700;
                  & > div {
                    margin: 0;
                  }
                `}
              >
                {message}
              </TextSpan>
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
