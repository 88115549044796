import { css, SerializedStyles } from '@emotion/react'

import { DotsLoader } from 'src/components/Loaders'
import PlainButton, { PlainButtonProps } from 'src/components/PlainButton'
import COLOR, { GRADIENT } from 'src/constants/color'
import { ELEMENT_HEIGHT, OUTLINE_THICKNESS } from 'src/constants/form-elements'
import { opacity } from 'src/utils/color'

export const baseButtonTextStyles = ({
  size,
}: {
  size: ButtonProps['size']
}): SerializedStyles => {
  // prettier-ignore
  const fontSize =
    size === 'small' ? 13 :
    size === 'medium' ? 14 :
    size === 'large' ? 18 :
    14

  return css`
    font-size: ${fontSize}px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.0875em;
  `
}

export const baseButtonStyles = ({
  size,
}: {
  size: ButtonProps['size']
}): SerializedStyles => {
  // prettier-ignore
  const height =
    size === 'small' ? ELEMENT_HEIGHT.SMALL :
    size === 'medium' ? ELEMENT_HEIGHT.MEDIUM :
    size === 'large' ? ELEMENT_HEIGHT.LARGE :
    ELEMENT_HEIGHT.DEFAULT

  // prettier-ignore
  const padding =
    size === 'small' ? 24 :
    size === 'medium' ? 40 :
    size === 'large' ? 48 :
    32

  return css`
    cursor: pointer;
    position: relative;
    z-index: 0;

    border: 0;
    border-radius: ${height / 2}px;
    padding: 0 ${padding}px;
    height: ${height}px;
    flex-shrink: 0;

    line-height: 1.5;
    color: ${COLOR.WHITE};
    background: ${COLOR.PURPLE_500};
    background: ${GRADIENT.PURPLE};

    overflow: hidden;

    :before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: background 0.25s;
    }
  `
}

export const alternateButtonStyles = (): SerializedStyles => css`
  color: ${COLOR.WHITE};
  background: ${COLOR.BLACK};
`

export const alternateInvertedButtonStyles = (): SerializedStyles => css`
  color: ${COLOR.BLACK};
  background: ${COLOR.WHITE};
`

export const dangerButtonStyles = (): SerializedStyles => css`
  color: ${COLOR.WHITE};
  background: ${COLOR.RED_500};
`

export const dangerSubtleButtonStyles = (): SerializedStyles => css`
  background: none;
  padding: 0 8px;
  margin: 0 -8px;

  color: ${COLOR.BLACK};
  :hover,
  :focus {
    color: ${COLOR.RED_500};
  }
`

export const secondaryButtonStyles = (): SerializedStyles => css`
  color: ${COLOR.WHITE};
  background: ${COLOR.GREY_01};
`

export const outlinedButtonStyles = (): SerializedStyles => css`
  color: ${COLOR.BLACK};
  border: 2px solid ${COLOR.BLACK};
  background: transparent;
`

export const unicornButtonStyles = (): SerializedStyles => css`
  color: ${COLOR.BLACK};
  background: ${COLOR.WHITE};
  background: ${GRADIENT.UNICORN};
`

export const loadingButtonStyles = ({
  variant,
}: {
  variant: ButtonProps['variant']
}): SerializedStyles => {
  // prettier-ignore
  const borderColor =
    variant === 'outlined' ? COLOR.GRAY_500 :
    undefined

  // prettier-ignore
  const backgroundColor =
    variant === 'alternate-inverted' ? COLOR.GRAY_100 :
    variant === 'outlined' ? COLOR.GRAY_100 :
    variant === 'unicorn' ? COLOR.GRAY_100 :
    variant === 'danger-subtle' ? 'none' :
    opacity(COLOR.BLACK, 0.4)

  return css`
    cursor: not-allowed;
    background: ${backgroundColor};
    border-color: ${borderColor};
  `
}

export const interactiveButtonStyles = ({
  variant,
}: {
  variant: ButtonProps['variant']
}): SerializedStyles => {
  // prettier-ignore
  const boxShadowColor =
    variant === 'alternate' ? opacity(COLOR.BLACK, 0.2) :
    variant === 'alternate-inverted' ? opacity(COLOR.WHITE, 0.4) :
    variant === 'outlined' ? opacity(COLOR.BLACK, 0.15) :
    variant === 'unicorn' ? opacity(COLOR.WHITE, 0.4) :
    variant === 'secondary' ? opacity(COLOR.GREY_01, 0.3) :
    variant === 'danger' ? opacity(COLOR.RED_500, 0.3) :
    variant === 'danger-subtle' ? opacity(COLOR.RED_500, 0.3) :
    opacity(COLOR.PURPLE_500, 0.4)

  // prettier-ignore
  const overlayBackgroundColor =
    variant === 'alternate' ? opacity(COLOR.GRAY_500, 0.2) :
    variant === 'alternate-inverted' ? COLOR.GRAY_100 :
    variant === 'outlined' ? COLOR.GRAY_100 :
    variant === 'unicorn' ? opacity(COLOR.WHITE, 0.5) :
    variant === 'danger' ? opacity(COLOR.WHITE, 0.15) :
    opacity(COLOR.WHITE, 0.2)

  return css`
    :focus {
      box-shadow: 0 0 0 ${OUTLINE_THICKNESS}px ${boxShadowColor};
    }

    :hover:before,
    :focus:before {
      background: ${overlayBackgroundColor};
    }
  `
}

export const interactiveButtonSpanStyles = css`
  position: relative;
  z-index: 2;
`

export const disabledButtonStyles = ({
  variant,
}: {
  variant: ButtonProps['variant']
}): SerializedStyles => {
  // prettier-ignore
  const color =
    variant === 'alternate-inverted' ? COLOR.GRAY_500 :
    variant === 'outlined' ? COLOR.GRAY_500 :
    variant === 'unicorn' ? COLOR.GRAY_500 :
    variant === 'danger-subtle' ? COLOR.GRAY_500 :
    undefined

  // prettier-ignore
  const borderColor =
    variant === 'outlined' ? COLOR.GRAY_500 :
    undefined

  // prettier-ignore
  const backgroundColor =
    variant === 'alternate-inverted' ? COLOR.GRAY_100 :
    variant === 'outlined' ? COLOR.GRAY_100 :
    variant === 'unicorn' ? COLOR.GRAY_100 :
    variant === 'danger-subtle' ? 'none' :
    COLOR.GRAY_500

  return css`
    cursor: not-allowed;
    background: ${backgroundColor};
    border-color: ${borderColor};

    &,
    :hover,
    :focus {
      color: ${color};
    }

    :hover:before,
    :focus:before {
      background: none;
    }
  `
}

export interface ButtonProps extends PlainButtonProps {
  loading?: boolean
  size?: Lowercase<keyof typeof ELEMENT_HEIGHT>
  variant?:
    | 'default'
    | 'alternate'
    | 'alternate-inverted'
    | 'secondary'
    | 'outlined'
    | 'unicorn'
    | 'danger'
    | 'danger-subtle'
}

export default function Button({
  loading,
  disabled,
  children,
  size,
  variant,
  ...props
}: ButtonProps): React.ReactElement {
  // prettier-ignore
  const loaderColor =
    variant === 'alternate-inverted' ? COLOR.GREY_02 :
    variant === 'outlined' ? COLOR.GREY_02 :
    variant === 'unicorn' ? COLOR.GREY_02 :
    variant === 'danger-subtle' ? COLOR.GREY_02 :
    COLOR.WHITE

  return (
    <PlainButton
      disabled={loading === true || disabled === true}
      css={[
        baseButtonTextStyles({ size }),
        baseButtonStyles({ size }),
        variant === 'alternate' ? alternateButtonStyles() : undefined,
        variant === 'alternate-inverted'
          ? alternateInvertedButtonStyles()
          : undefined,
        variant === 'outlined' ? outlinedButtonStyles() : undefined,
        variant === 'unicorn' ? unicornButtonStyles() : undefined,
        variant === 'danger' ? dangerButtonStyles() : undefined,
        variant === 'danger-subtle' ? dangerSubtleButtonStyles() : undefined,
        variant === 'secondary' ? secondaryButtonStyles() : undefined,
        loading === true
          ? loadingButtonStyles({ variant })
          : interactiveButtonStyles({ variant }),
        disabled === true ? disabledButtonStyles({ variant }) : undefined,
      ]}
      {...props}
    >
      {loading === true ? (
        <span
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
        >
          <DotsLoader data-testid='button-loader' color={loaderColor} />
        </span>
      ) : undefined}
      <span
        css={[
          interactiveButtonSpanStyles,
          loading === true
            ? css`
                opacity: 0;
              `
            : undefined,
        ]}
      >
        {children}
      </span>
    </PlainButton>
  )
}
