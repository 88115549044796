import { startAuthentication } from '@simplewebauthn/browser';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import LINK from 'src/constants/link';
import { IGNORE_CACHED_TOKEN_ROUTES, REDIRECT_QUERY_PARAM } from 'src/constants/route';
import useAuthError from 'src/features/auth/hooks/useAuthError';
import useApi from 'src/hooks/useApi';
import useTranslate from 'src/hooks/useTranslate';
import { useOptionalUser, useSetUser, useUserLoading, useSetUserLoading } from 'src/hooks/useUser';
import indefinite from 'src/utils/indefinite';
import { isPasswordStrong } from 'src/utils/password';
function useFetchLoggedInUser() { const { fetch: getMe } = useApi({ serviceName: 'vault', path: 'users/me' }); return async function fetchLoggedInUser() { const meResult = await getMe(); if (meResult.error != null) {
    return { error: meResult.error.message };
} return meResult.data; }; }
function useSwapHandoffToken() { const { fetch: swapHandoffTokenRequest } = useApi({ serviceName: 'vault', method: 'POST', path: 'authTokens' }); return async function swapHandoffToken(handoffToken) { const handoffResult = await swapHandoffTokenRequest({ token: handoffToken }); if (handoffResult.data != null && 'userId' in handoffResult.data && handoffResult.data.userId != null) {
    return handoffResult.data.userId;
} console.error('Unable to swap handoff token %o', handoffResult); return { error: 'Unable to swap handoff token' }; }; }
export function useLogIn() {
    const [internalRedirect] = useQueryParam(REDIRECT_QUERY_PARAM, StringParam);
    const setUser = useSetUser();
    const { parseRemoteError } = useAuthError();
    const { fetch: logIntoVault } = useApi({ serviceName: 'vault', method: 'POST', path: 'auth/local' });
    const { fetch: logIntoVaultSso } = useApi({ serviceName: 'vault', method: 'POST', path: 'auth/local' });
    const fetchLoggedInUser = useFetchLoggedInUser();
    const [ssoClient] = useQueryParam('ssoClient', StringParam);
    return useCallback(async function logIn(_ref) {
        let { email, password, captchaToken, totp: otp } = _ref;
        if (ssoClient != null && ssoClient !== '') {
            const ssoResponse = await logIntoVaultSso({ body: { email, password, otp }, query: { ssoClient } });
            if (ssoResponse.error != null) {
                return parseRemoteError({ res: ssoResponse, credentials: { email, password, captchaToken } });
            } // TODO: This will need to be more dynamic once we support different SSO clients
            const ssoClientUrl = LINK.ACADEMY({ token: ssoResponse.data.token }); // Await indefinitely so the user is redirected
            await indefinite(() => { window.location.href = ssoClientUrl; });
            const userResult = await fetchLoggedInUser();
            if ('error' in userResult) {
                return { error: userResult.error };
            } // Set the user in the recoil state
            setUser(userResult);
            return { user: userResult };
        }
        const vaultAuth = await logIntoVault({ body: { email, password, otp, redirect: internalRedirect, ...(captchaToken != null && { 'h-captcha-response': captchaToken }) } });
        if (vaultAuth.error != null) {
            return parseRemoteError({ res: vaultAuth, credentials: { email, password, captchaToken } });
        }
        const userResult = await fetchLoggedInUser();
        if ('error' in userResult) {
            return { error: userResult.error };
        } // Set the user in the recoil state
        setUser(userResult);
        return { user: userResult };
    }, [ssoClient, logIntoVault, fetchLoggedInUser, setUser, logIntoVaultSso, parseRemoteError, internalRedirect]);
}
export function useSignUp() { const logIn = useLogIn(); const { fetch: signUpWithVault } = useApi({ serviceName: 'vault', method: 'POST', path: 'users' }); const { fetch: setCommunicationPreferences } = useApi({ serviceName: 'vault', method: 'PUT', path: 'users/{userId}/communicationPreferences' }); const { translate } = useTranslate(); return useCallback(async function signUp(email, password, captchaToken, captchaTokenForLogin) { let allowMarketingEmails = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false; const isValidPassword = isPasswordStrong(password); if (!isValidPassword) {
    toast.error(translate('enter_strong_password'));
    return { error: 'Password is not strong enough' };
} const signUpResult = await signUpWithVault({ body: { email, password, agreedToTerms: true, 'h-captcha-response': captchaToken } }); if (signUpResult.data == null || !('userId' in signUpResult.data)) {
    console.error('Unable to sign up %o', signUpResult);
    toast.error(translate('unknown_signup_error'));
    return { error: 'Unable to register new user' };
} const loginInResult = await logIn({ email, password, captchaToken: captchaTokenForLogin }); await setCommunicationPreferences({ body: { allowMarketingEmails }, pathData: { userId: signUpResult.data.userId } }); return loginInResult; }, [logIn, signUpWithVault, setCommunicationPreferences, translate]); }
export function useLogOut() { const setUser = useSetUser(); const { fetch: logOutOfVault } = useApi({ serviceName: 'vault', method: 'DELETE', path: 'auth/local' }); const { fetch: logOutOfCommerce } = useApi({ serviceName: 'commerce', method: 'DELETE', path: 'accessTokens' }); return useCallback(function logOut() { void logOutOfVault(); void logOutOfCommerce(); setUser(null); }, [setUser, logOutOfVault, logOutOfCommerce]); }
export function useResetPassword() { const { fetch: resetPasswordRequest } = useApi({ serviceName: 'vault', method: 'POST', path: 'users/resetPassword' }); return useCallback(async function resetPassword(email) { await resetPasswordRequest({ body: { email } }); }, [resetPasswordRequest]); }
export function useUserFromToken() {
    const fetchLoggedInUser = useFetchLoggedInUser();
    const swapHandoffToken = useSwapHandoffToken();
    const user = useOptionalUser();
    const setUser = useSetUser();
    const userLoading = useUserLoading();
    const setUserLoading = useSetUserLoading();
    const location = useLocation();
    const [handoffToken, setHandoffToken] = useQueryParam('handoffToken', StringParam);
    const reload = useCallback(async function reload() { setUserLoading('reloading'); const userResult = await fetchLoggedInUser(); if (!('error' in userResult)) {
        setUser(userResult);
    } setUserLoading('loaded'); }, [fetchLoggedInUser, setUser, setUserLoading]); // When the hook initializes, fetch the user based on the token
    useEffect(() => {
        void run().then(() => { setUserLoading('loaded'); });
        async function run() {
            if (handoffToken != null && handoffToken !== '') {
                setHandoffToken(undefined); // Swap the handoff token for a full token
                const swapResult = await swapHandoffToken(handoffToken);
                if (typeof swapResult !== 'string') {
                    return;
                } // Finally, fetch the logged in user
                const userResult = await fetchLoggedInUser();
                if (!('error' in userResult)) {
                    setUser(userResult);
                }
                return;
            } // If the route should ignore any previously cached tokens, do nothing
            const isIgnoreTokenRoute = IGNORE_CACHED_TOKEN_ROUTES.some(route => matchPath(location.pathname, { path: route }));
            if (isIgnoreTokenRoute) {
                return;
            } // Attempt to fetch the user that is already logged in
            const userResult = await fetchLoggedInUser();
            if (!('error' in userResult)) {
                setUser(userResult);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return { user, reload, userLoading };
}
export function usePasskeyLogin(_ref2) {
    let { setHelpType } = _ref2;
    const { fetch: requestAuthOptions } = useApi({ serviceName: 'vault', method: 'POST', path: 'passkeys/getAuthOptions' });
    const { fetch: logIntoVault } = useApi({ serviceName: 'vault', method: 'POST', path: 'auth/local' });
    const fetchLoggedInUser = useFetchLoggedInUser();
    const setUser = useSetUser();
    const { parseRemoteError, parsePasskeyPromptError } = useAuthError(); /**
       * Passkey prompting must be triggered exactly once, and batched React state changes may not
       * be batched frequently enough to guarantee this. If multiple clicks are made in quick
       * succession, the browsers dismissal of subsequent prompts appear identical to
       * unsupported browser behavior, which may lead to misleading error messaging.
       *
       * To prevent multiple prompts, the click itself is used to trigger a single state change,
       * which then verifies that the prompt has not already been triggered before proceeding.
       */
    const [submissionLoading, setSubmissionLoading] = useState(false); /**
    *  False indicates that no submission has been initiated, string or undefined indicates that a submission
    *  has been initiated with the state variable as the initiation value
    */
    const [submissionInitiation, setSubmissionInitiation] = useState(false);
    const loginWithPasskey = useCallback(email => {
        if (submissionLoading || submissionInitiation !== false) { /**
              * This should be prevented with button disabling in the UI, but in case a second user-click is still
              * registered while a React batch state update is in progress, it should be impossible to trigger
              * multiple prompts
              */
            console.warn('Passkey prompt already in progress, ignoring additional clicks');
            return;
        }
        setSubmissionInitiation(email);
    }, [submissionLoading, submissionInitiation]);
    const startSubmission = useCallback(async function (email) {
        const optionsResponse = await requestAuthOptions({ body: { email } });
        if (optionsResponse.error != null) {
            return parseRemoteError({ res: optionsResponse, setHelpType });
        }
        const { token, options } = optionsResponse.data;
        let passkeyPromptResponse;
        let passkeyPromptError;
        const startTime = Date.now();
        try {
            passkeyPromptResponse = await startAuthentication(options);
        }
        catch (error) {
            passkeyPromptError = error;
        }
        if (passkeyPromptError != null) {
            const result = parsePasskeyPromptError({ error: passkeyPromptError, setHelpType, timeToFailureMs: Date.now() - startTime });
            console.error('Passkey prompt error %o', result);
            return result;
        }
        if (passkeyPromptResponse == null) {
            return { error: 'No passkey response' };
        }
        const loginResponse = await logIntoVault({ body: { passkeyOptions: token, // @ts-expect-error AuthResponseJSON is serialized to JSON
                passkeyAuth: passkeyPromptResponse } });
        if (loginResponse.error != null) {
            return parseRemoteError({ res: loginResponse, setHelpType });
        }
        const userResult = await fetchLoggedInUser();
        if ('error' in userResult) {
            return { error: userResult.error };
        }
        setUser(userResult);
        return { user: userResult };
    }, [requestAuthOptions, logIntoVault, fetchLoggedInUser, setUser, parseRemoteError, parsePasskeyPromptError, setHelpType]);
    useEffect(() => { if (submissionInitiation === false || submissionLoading) {
        return;
    } setSubmissionLoading(true); void (async () => { await startSubmission(submissionInitiation); setSubmissionInitiation(false); setSubmissionLoading(false); })(); }, [submissionLoading, startSubmission, submissionInitiation]);
    return { loginWithPasskey, loading: submissionLoading };
}
