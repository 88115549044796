import { USER_ROLE, USER_ROLES, SORTED_USER_ROLES, PAID_USER_ROLES, PREMIUM_OR_HIGHER_USER_ROLES, LEGACY_USER_ROLES } from 'src/constants/user';
export function isUserRole(plan) { return plan != null && USER_ROLES.includes(plan); }
export function isPaidUserRole(plan) { return isUserRole(plan) && PAID_USER_ROLES.includes(plan); }
export function isLegacyUserRole(plan) { return isUserRole(plan) && LEGACY_USER_ROLES.includes(plan); }
export function isPremiumOrHigherUserRole(plan) { return isUserRole(plan) && PREMIUM_OR_HIGHER_USER_ROLES.includes(plan); }
export function is5KeyVaultUserRole(plan) { return isPremiumOrHigherUserRole(plan) || plan === USER_ROLE.STANDARD_PLUS; }
export function isUpgrade(plan, userPlan) { if (plan === USER_ROLE.STANDARD && userPlan === USER_ROLE.GOLD) {
    return false;
} if (plan === USER_ROLE.PREMIUM && userPlan === USER_ROLE.PLATINUM) {
    return false;
} if (plan === USER_ROLE.PRIVATE_CLIENT && userPlan === USER_ROLE.DIAMOND) {
    return false;
} return SORTED_USER_ROLES.indexOf(plan) > SORTED_USER_ROLES.indexOf(userPlan); }
export function isDowngrade(plan, userPlan) { return SORTED_USER_ROLES.indexOf(userPlan) > SORTED_USER_ROLES.indexOf(plan); }
export function getFullName(_ref) { let { firstName, lastName } = _ref; if (firstName != null && firstName !== '' && lastName != null && lastName !== '') {
    return "".concat(firstName, " ").concat(lastName);
} if (firstName != null && firstName !== '') {
    return firstName;
} if (lastName != null && lastName !== '') {
    return lastName;
} return ''; }
export function getNewRoleEquivalent(role) { if (role === USER_ROLE.SILVER) {
    return USER_ROLE.FREE;
} if (role === USER_ROLE.PLATINUM) {
    return USER_ROLE.PREMIUM;
} if (role === USER_ROLE.DIAMOND) {
    return USER_ROLE.PRIVATE_CLIENT;
} return role; }
export function getCheckoutRoleEquivalent(role) { if (role === USER_ROLE.GOLD) {
    return USER_ROLE.STANDARD;
} if (role === USER_ROLE.PLATINUM) {
    return USER_ROLE.PREMIUM;
} if (role === USER_ROLE.DIAMOND) {
    return USER_ROLE.PRIVATE_CLIENT;
} return role; }
