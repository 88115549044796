import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

interface RootProvidersProps {
  children: React.ReactElement | React.ReactElement[]
}

export default function RootProviders({
  children,
}: RootProvidersProps): React.ReactElement {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <RecoilRoot>{children}</RecoilRoot>
      </QueryParamProvider>
    </BrowserRouter>
  )
}
