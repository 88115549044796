import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { baseTextStyles } from 'src/components/Text'
import COLOR from 'src/constants/color'
import {
  BORDER_RADIUS,
  BORDER_WIDTH,
  OUTLINE_THICKNESS,
} from 'src/constants/form-elements'
import { ReactComponent as CheckboxIcon } from 'src/images/checkbox-icon.svg'

type CSSInterpolation = Parameters<typeof css>[0]

export const CheckboxLabel = styled.label``

export interface CheckboxInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'label'> {
  inputCss?: CSSInterpolation | CSSInterpolation[]
  labelCss?: CSSInterpolation | CSSInterpolation[]
  labelContent: React.ReactElement
  state?: 'error'
}

export default forwardRef(function CheckboxInput(
  {
    inputCss,
    labelCss,
    labelContent,
    checked,
    state,
    ...props
  }: CheckboxInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement {
  return (
    <CheckboxLabel
      css={[
        css`
          cursor: pointer;

          ${baseTextStyles}
          line-height: 1.4;

          display: inline-grid;
          grid-template-columns: auto 1fr;
          grid-gap: 12px;
          align-items: flex-start;
        `,
        labelCss,
      ]}
    >
      <input
        {...props}
        css={css`
          pointer-events: none;
          opacity: 0;
          position: absolute;
        `}
        ref={ref}
        type='checkbox'
        checked={checked}
      />
      <div
        css={[
          inputCss,
          css`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 24px;
            height: 24px;

            border-radius: ${BORDER_RADIUS}px;
            border: ${BORDER_WIDTH}px solid ${COLOR.BLACK};
            background: ${COLOR.WHITE};

            input:hover:not(:disabled):not(:focus):not(:checked) + & {
              background: ${COLOR.PURPLE_500}33;
            }
            input:focus:not(:disabled) + & {
              border-color: ${COLOR.PURPLE_500};
              box-shadow: 0 0 0 ${OUTLINE_THICKNESS}px ${COLOR.PURPLE_500}33;
            }
            input:checked + & {
              border-color: ${COLOR.PURPLE_500};
              background: ${COLOR.PURPLE_500};
            }
          `,
          state === 'error' &&
            css`
              border-color: ${COLOR.PINK_500};

              input:hover:not(:disabled):not(:focus):not(:checked) + & {
                background: ${COLOR.PINK_500}33;
              }
              input:focus:not(:disabled) + & {
                border-color: ${COLOR.PINK_500};
                box-shadow: 0 0 0 ${OUTLINE_THICKNESS}px ${COLOR.PINK_500}33;
              }
            `,
        ]}
      >
        <CheckboxIcon fill={COLOR.WHITE} width='14' />
      </div>
      <div>{labelContent}</div>
    </CheckboxLabel>
  )
})
