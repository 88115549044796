/**
 * Environment variable management
 * - Loads hierarchical environment variables from files according to NODE_ENV
 * - Validates and coerces environment variables through schema
 * - Schema information used for typescript inferences
 *
 * File exports two different schemas:
 * - appSchema: used in application, a subset of the config since React apps are client-side
 * - buildSchema: used at build-time, a superset of config including appSchema
 */ import { z } from 'zod';
const MAX_PORT_NUMBER = 65536; // Coercing schemas for string-based ENV variables
const portSchema = z.number().positive().max(MAX_PORT_NUMBER);
const boolSchema = z.boolean(); // const positiveNumberSchema = z.number().positive()
const nonEmptyStringSchema = z.string().min(1); // Unions not yet supported within zvn
// const domainOrIpSchema = z.union([z.string().url(), z.string().ip()])
const domainOrIpSchema = nonEmptyStringSchema;
const validNodeEnvs = ['standalone', 'development', 'test', 'staging', 'production'];
const validLoggerEnvs = ['standalone', 'local', 'dev', 'stg', 'prod'];
const reactSchema = { REACT_APP_APP_ENV: z.enum(validLoggerEnvs), REACT_APP_BASE_URL: z.string().url(), REACT_APP_VAULT_API_ORIGIN: domainOrIpSchema, REACT_APP_GATEWAY_API_ORIGIN: domainOrIpSchema, REACT_APP_COMMERCE_API_ORIGIN: domainOrIpSchema, REACT_APP_BTCPAY_URL: nonEmptyStringSchema, REACT_APP_DATADOG_TOKEN: z.string().optional(), REACT_APP_HCAPTCHA_SITEKEY: nonEmptyStringSchema, REACT_APP_HCAPTCHA_TEST_RESPONSE_TOKEN: nonEmptyStringSchema, REACT_APP_LAUNCHDARKLY_CLIENTSIDE_ID: z.string(), REACT_APP_STRIPE_KEY: nonEmptyStringSchema, REACT_APP_FEATURE_API_KEYS: boolSchema, // Determines which config file hierarchy to use
    NODE_ENV: z.enum(validNodeEnvs) }; // Define a zod/znv schema for all process environment variables
// NOTE: these are a subset of zod rules/types, znv is responsibly coercing values to the correct types
const fullSchema = { VERSION_FILE: z.string().optional(), PORT: portSchema, HOST: domainOrIpSchema.optional(), HTTPS: boolSchema, // Test-only
    CYPRESS_EMAIL_USER: z.string().optional(), CYPRESS_MAILSAC: z.string().optional(), ...reactSchema };
export const buildSchema = fullSchema;
export const appSchema = reactSchema;
