import { css, SerializedStyles } from '@emotion/react'
import { HTMLProps } from 'react'
import { matchPath } from 'react-router'
import {
  Link,
  NavLink,
  LinkProps,
  useLocation,
  useHistory,
} from 'react-router-dom'

import { TextSpan } from 'src/components/Text'
import COLOR from 'src/constants/color'
import ROUTE, { BUNDLE } from 'src/constants/route'
import useTranslate from 'src/hooks/useTranslate'

const linkStyles = css`
  color: ${COLOR.PURPLE_500};
  font-weight: 600;
  text-decoration: none;

  ${TextSpan} &,
  & ${TextSpan} {
    font-weight: inherit;
  }

  :hover,
  :focus {
    text-decoration: underline;
    outline-color: ${COLOR.PURPLE_500};
  }
`

export interface ExternalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  innerCss?: SerializedStyles
  openInSameTab?: boolean
}

export function ExternalLink({
  children,
  innerCss,
  openInSameTab,
  ...props
}: ExternalLinkProps): React.ReactElement {
  const { translateToString } = useTranslate()
  return (
    <a
      title={
        openInSameTab === true
          ? undefined
          : translateToString('opens_in_new_tab')
      }
      css={linkStyles}
      {...props}
      target={openInSameTab === true ? undefined : '_blank'}
      rel='noopener noreferrer'
    >
      <TextSpan css={innerCss}>{children}</TextSpan>
    </a>
  )
}

export interface InternalLinkProps<LinkState> extends LinkProps<LinkState> {
  activeCss?: string
  innerCss?: SerializedStyles
  // eslint-disable-next-line @typescript-eslint/ban-types
  to: Exclude<LinkProps<LinkState>['to'], Function>
}

export function InternalLink<LinkState>({
  children,
  activeCss,
  innerCss,
  to,
  ...props
}: InternalLinkProps<LinkState>): React.ReactElement {
  const history = useHistory()
  const location = useLocation()

  // Keep the location we are navigating from in the state so we can navigate back if needed
  const toObject = typeof to === 'string' ? { pathname: to } : to
  toObject.state = {
    ...toObject.state,
    fromLocation: location,
  } as unknown as LinkState

  const isLinkToHome = to === ROUTE.HOME
  const isLinkToPdf = typeof to === 'string' && to.endsWith('.pdf')
  const target = isLinkToPdf ? '_blank' : undefined

  const content = <TextSpan css={innerCss}>{children}</TextSpan>

  const navigatingToAnotherBundle =
    !isLinkToHome &&
    !isLinkToPdf &&
    getBundle(toObject.pathname ?? '') !==
      getBundle(location.pathname, { can404: true })
  if (isLinkToHome || navigatingToAnotherBundle) {
    const href = history.createHref(toObject)
    return (
      <a css={linkStyles} {...props} href={href} target={target}>
        {content}
      </a>
    )
  }

  if (activeCss == null) {
    return (
      <Link css={linkStyles} {...props} to={toObject} target={target}>
        {content}
      </Link>
    )
  }

  return (
    <NavLink
      css={[
        linkStyles,
        css`
          &.active {
            ${activeCss}
          }
        `,
      ]}
      {...props}
      to={toObject}
    >
      {content}
    </NavLink>
  )
}

export interface PseudoLinkProps
  extends Omit<HTMLProps<HTMLSpanElement>, 'as'> {
  activeCss?: string
  innerCss?: SerializedStyles
  onClick: () => void
}

function getBundle(
  pathname: string,
  { can404 }: { can404?: boolean } = {},
): string {
  for (const [bundle, routes] of Object.entries(BUNDLE)) {
    if (
      routes.find((r) =>
        matchPath(pathname.replace(/\?.*/, ''), { path: r }),
      ) != null
    ) {
      return bundle
    }
  }
  if (can404 === true) {
    return '404'
  }
  throw new Error(`Unable to find bundle for ${pathname}`)
}
