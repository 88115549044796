import { useState, useEffect } from 'react'; // Adopted from: https://usehooks.com/useScript
export default function useScript(src) {
    let { id } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const [status, setStatus] = useState(src != null ? 'loading' : 'idle');
    useEffect(() => {
        if (src == null || src === '') {
            setStatus('idle');
            return;
        }
        const script = createScript(src, id);
        setStatus(script.getAttribute('data-status')); // Set up listeners ONCE to update the data-status attribute
        if (script.getAttribute('data-initialized') !== 'true') {
            const setAttributeFromEvent = event => { script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error'); };
            script.addEventListener('load', setAttributeFromEvent);
            script.addEventListener('error', setAttributeFromEvent);
            script.setAttribute('data-initialized', 'true');
        } // Update the status of the script in state
        // NOTE: Even if the script exists, set event handlers for *this* hook instance
        const setStateFromEvent = event => { setStatus(event.type === 'load' ? 'ready' : 'error'); };
        script.addEventListener('load', setStateFromEvent);
        script.addEventListener('error', setStateFromEvent);
        return () => { script.removeEventListener('load', setStateFromEvent); script.removeEventListener('error', setStateFromEvent); };
    }, [src, id]);
    return status;
}
function createScript(src) { let id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ''; const existingScript = document.querySelector("script[src=\"".concat(src, "\"]")); if (existingScript) {
    return existingScript;
} const script = document.createElement('script'); script.src = src; script.id = id; script.async = true; script.setAttribute('data-status', 'loading'); document.body.appendChild(script); return script; }
