import qs from 'qs';
import { matchPath } from 'react-router';
import ROUTE, { PUBLIC_ROUTES, OPEN_ROUTES, REDIRECT_QUERY_PARAM } from 'src/constants/route';
import { USER_ROLE } from 'src/constants/user';
export function generatePathWithQuery(route, queryObject) { const query = qs.stringify(queryObject, { arrayFormat: 'repeat', encodeValuesOnly: true }); return query.length > 0 ? "".concat(route, "?").concat(query) : route; } /**
 * Returns a list of redirect routes based on the provided feature flag set.
 * @returns list of redirect routes
 */
export function getRedirectRoutes() { const redirectRoutes = { [ROUTE.SETTINGS]: ROUTE.BILLING, '/trial/gold': generatePathWithQuery(ROUTE.CHECKOUT, { plan: USER_ROLE.GOLD }), '/subscribe/gold': generatePathWithQuery(ROUTE.CHECKOUT, { plan: USER_ROLE.GOLD }), '/checkout/platinum': generatePathWithQuery(ROUTE.CHECKOUT, { plan: USER_ROLE.PREMIUM }), '/subscribe/platinum': generatePathWithQuery(ROUTE.CHECKOUT, { plan: USER_ROLE.PREMIUM }), '/checkout/diamond': generatePathWithQuery(ROUTE.CHECKOUT, { plan: USER_ROLE.PRIVATE_CLIENT }), '/subscribe/diamond': generatePathWithQuery(ROUTE.CHECKOUT, { plan: USER_ROLE.PRIVATE_CLIENT }) }; return redirectRoutes; } /**
* Gets the location to redirect to if a logged in user visits a public-only page
* or a logged out user visits a private-only page.
*/
export function getInitialRedirect(location, user, authedRedirect) { const isPublicRoute = PUBLIC_ROUTES.some(route => matchPath(location.pathname, { path: route })); const isOpenRoute = OPEN_ROUTES.some(route => { if (location.pathname === ROUTE.SUBSCRIBE_PLAN_STANDARD_PLUS) {
    return false;
} return matchPath(location.pathname, { path: route }); }); const loggedIn = user != null; if (loggedIn && isPublicRoute) {
    const defaultAuthedRedirect = user.verifiedEmail === true ? ROUTE.OVERVIEW : ROUTE.VERIFY_EMAIL_RESEND;
    return authedRedirect !== null && authedRedirect !== void 0 ? authedRedirect : defaultAuthedRedirect;
} if (!loggedIn && !isPublicRoute && !isOpenRoute) {
    const search = "?".concat(REDIRECT_QUERY_PARAM, "=").concat(encodeURIComponent(location.pathname + location.search));
    return { pathname: ROUTE.LOGIN, search: location.pathname === ROUTE.HOME ? undefined : search };
} return null; }
