import { Global, css } from '@emotion/react'

import maisonNeueBoldUrl from 'src/fonts/Maison-Neue/MaisonNeue-Bold.woff'
import maisonNeueBold2Url from 'src/fonts/Maison-Neue/MaisonNeue-Bold.woff2'
import maisonNeueBoldItalicUrl from 'src/fonts/Maison-Neue/MaisonNeue-BoldItalic.woff'
import maisonNeueBoldItalic2Url from 'src/fonts/Maison-Neue/MaisonNeue-BoldItalic.woff2'
import maisonNeueBookUrl from 'src/fonts/Maison-Neue/MaisonNeue-Book.woff'
import maisonNeueBook2Url from 'src/fonts/Maison-Neue/MaisonNeue-Book.woff2'
import maisonNeueBookItalicUrl from 'src/fonts/Maison-Neue/MaisonNeue-BookItalic.woff'
import maisonNeueBookItalic2Url from 'src/fonts/Maison-Neue/MaisonNeue-BookItalic.woff2'
import maisonNeueMediumUrl from 'src/fonts/Maison-Neue/MaisonNeue-Medium.woff'
import maisonNeueMedium2Url from 'src/fonts/Maison-Neue/MaisonNeue-Medium.woff2'

import maisonNeueMonoBoldUrl from 'src/fonts/Maison-Neue-Mono/MaisonNeueMono-Bold.woff'
import maisonNeueMonoBold2Url from 'src/fonts/Maison-Neue-Mono/MaisonNeueMono-Bold.woff2'
import maisonNeueMonoRegularUrl from 'src/fonts/Maison-Neue-Mono/MaisonNeueMono-Regular.woff'
import maisonNeueMonoRegular2Url from 'src/fonts/Maison-Neue-Mono/MaisonNeueMono-Regular.woff2'

const fontFaceStyles = css`
  @font-face {
    font-family: MaisonNeue;
    src: url(${maisonNeueBold2Url}) format('woff2'),
      url(${maisonNeueBoldUrl}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: MaisonNeue;
    src: url(${maisonNeueBoldItalic2Url}) format('woff2'),
      url(${maisonNeueBoldItalicUrl}) format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: MaisonNeue;
    src: url(${maisonNeueMedium2Url}) format('woff2'),
      url(${maisonNeueMediumUrl}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: MaisonNeue;
    /*
      we don't have the "medium" variant to match the 400 font-weight,
      so we just use the "book" variant instead.
     */
    src: url(${maisonNeueBookItalic2Url}) format('woff2'),
      url(${maisonNeueBookItalicUrl}) format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: MaisonNeue;
    src: url(${maisonNeueBook2Url}) format('woff2'),
      url(${maisonNeueBookUrl}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: MaisonNeue;
    src: url(${maisonNeueBookItalic2Url}) format('woff2'),
      url(${maisonNeueBookItalicUrl}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: MaisonNeueMono;
    src: url(${maisonNeueMonoBold2Url}) format('woff2'),
      url(${maisonNeueMonoBoldUrl}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: MaisonNeueMono;
    src: url(${maisonNeueMonoRegular2Url}) format('woff2'),
      url(${maisonNeueMonoRegularUrl}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
`

const baseStyles = css`
  * {
    box-sizing: border-box;
  }

  /* Set the selection as the default used by Safari */
  *::selection {
    background: #b4d5fe;
    color: #18181f;
  }

  html,
  body,
  #root {
    width: 100%;
    min-height: 100vh;
    display: flex;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-weight: 300;

    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  img,
  svg {
    vertical-align: bottom;
  }
  img {
    max-width: 100%;
    height: auto;
  }

  summary {
    cursor: pointer;
  }

  strong {
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  ul,
  ol {
    margin: 0;
  }
`

export default function RootStyles(): React.ReactElement {
  return (
    <>
      <Global styles={fontFaceStyles} />
      <Global styles={baseStyles} />
    </>
  )
}
