// Ported from https://github.com/autonomoussoftware/fast-password-entropy/blob/098865e7943622e763a7b1f40592832f318edbb0/src/index.js
// in order to add types
/**
 * Calculate the entropy of a string based on the size of the charset used and
 * the length of the string.
 *
 * Based on:
 * http://resources.infosecinstitute.com/password-security-complexity-vs-length/
 */ function calcEntropy(charset, length) { return Math.round(length * Math.log(charset) / Math.LN2); } /**
* Standard character sets list.
*
* It assumes the `uppercase` and `lowercase` charsets to have 26 chars as in
* the English alphabet. Numbers are 10 characters long. Symbols are the rest
* of the 33 remaining chars in the 7-bit ASCII table.
*/
const stdCharsets = [{ name: 'lowercase', re: /[a-z]/, // abcdefghijklmnopqrstuvwxyz
        length: 26 }, { name: 'uppercase', re: /[A-Z]/, // ABCDEFGHIJKLMNOPQRSTUVWXYZ
        length: 26 }, { name: 'numbers', re: /[0-9]/, // 1234567890
        length: 10 }, { name: 'symbols', re: /[^a-zA-Z0-9]/, //  !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ (and any other)
        length: 33 }]; /**
 * Creates a function to calculate the total charset length of a string based on
 * the given charsets.
 */
function calcCharsetLengthWith(charsets) { return text => charsets.reduce((length, charset) => length + (charset.re.test(text) ? charset.length : 0), 0); } /**
* Helper function to calculate the total charset lengths of a given string
* using the standard character sets.
*/
const calcCharsetLength = calcCharsetLengthWith(stdCharsets); /**
* Calculate the given password entropy.
*/
export default function passwordEntropy(text) { return text.length > 0 ? calcEntropy(calcCharsetLength(text), text.length) : 0; }
